/*=========================================================================================
  File Name: modulePositionMutations.js
  Description: Position Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Positions.unshift(item)
  },
  SET_Position(state, Positions) {
    state.Positions = Positions
  },
  UPDATE_Position(state, Position) {
    const PositionIndex = state.Positions.findIndex((p) => p.ID == Position.ID)
    Object.assign(state.Positions[PositionIndex], Position)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Positions.findIndex((p) => p.ID == itemId)
    state.Positions.splice(ItemIndex, 1)
},
}
