/*=========================================================================================
  File Name: modulePositionActions.js
  Description: Position Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddPosition({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Position/AddPosition", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllPositions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Position/GetAllPositions")
        .then((response) => {
          commit('SET_Position', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdatePosition({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Position/UpdatePosition", item)
        .then((response) => {
          commit('UPDATE_Position', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetPosition({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/Position/GetPosition", itemid)
        .then((response) => {
          commit('UPDATE_Position', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeletePosition({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Position/DeletePosition?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
